import { handleRequest } from "@helpers";
import { MoodPayload, MoodState, MoodStats } from "@types";
import { getHeaders } from "./helpers";

const remoteApiUrl = process.env.REACT_APP_REMOTE_API_URL;

const createMood = async (payload: MoodPayload) => {
  const urlencoded = new URLSearchParams();
  for (const [key, value] of Object.entries(payload)) {
    urlencoded.append(key, value);
  }
  const params = {
    url: `${remoteApiUrl}/mood`,
    options: {
      ...getHeaders(),
      method: "POST",
      body: urlencoded,
    },
    errorMsg: "Error creating mood.",
  };
  return handleRequest(params);
};

const updateMood = async (id: string, payload: MoodPayload) => {
  const urlencoded = new URLSearchParams();
  for (const [key, value] of Object.entries(payload)) {
    urlencoded.append(key, value);
  }
  const params = {
    url: `${remoteApiUrl}/mood/${id}`,
    options: {
      ...getHeaders(),
      method: "PUT",
      body: urlencoded,
    },
    errorMsg: "Error updating mood.",
  };
  return handleRequest(params);
};

const getMoods = async (
  filter: "all" | "hidden" | "own" = "all",
  pagination?: {
    itemsPerPage: number;
    lastItemTimestamp?: number;
  }
): Promise<Array<MoodState>> => {
  let url = `${remoteApiUrl}/mood?filter=${filter}`;
  if (pagination) {
    const { itemsPerPage, lastItemTimestamp } = pagination;
    url = url + `&itemsPerPage=${itemsPerPage}`;
    if (lastItemTimestamp)
      url = url + `&lastItemTimestamp=${lastItemTimestamp}`;
  }
  const params = {
    url,
    options: getHeaders(),
    errorMsg: `Error getting ${filter} moods.`,
  };
  const moods = await handleRequest(params);
  return moods;
};

const deleteMood = async (id: string) => {
  const params = {
    url: `${remoteApiUrl}/mood/${id}`,
    options: {
      ...getHeaders(),
      method: "DELETE",
    },
    errorMsg: "Error deleting mood.",
  };
  return handleRequest(params);
};

const getMoodStats = async (filters?: {
  groupBy?: "day" | "week";
  startTime?: string;
}): Promise<MoodStats> => {
  const { groupBy, startTime } = filters || {};
  let url = `${remoteApiUrl}/mood/stats`;
  url = url + `?groupBy=${groupBy || "day"}`;
  if (startTime) {
    url = url + `&startTime=${startTime}`;
  }
  const params = {
    url,
    options: getHeaders(),
    errorMsg: "Error getting mood stats.",
  };
  const stats = await handleRequest(params);
  return stats;
};

const getMoodsById = async (ids: Array<string>): Promise<Array<MoodState>> => {
  if (!ids.length) return [];
  const url = `${remoteApiUrl}/mood/${ids.join(",")}`;
  const params = {
    url,
    options: getHeaders(),
    errorMsg: `Error getting moods by id.`,
  };
  const moods = await handleRequest(params);
  return moods;
};

const createMoodReply = async (id: string, message: string) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append("message", message);

  const params = {
    url: `${remoteApiUrl}/mood/${id}/comment`,
    options: {
      ...getHeaders(),
      method: "POST",
      body: urlencoded,
    },
    errorMsg: "Error creating mood reply.",
  };
  return handleRequest(params);
};

const updateMoodReply = async (id: string, message: string) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append("message", message);

  const params = {
    url: `${remoteApiUrl}/mood/comment/${id}`,
    options: {
      ...getHeaders(),
      method: "PUT",
      body: urlencoded,
    },
    errorMsg: "Error updating mood reply.",
  };
  return handleRequest(params);
};

const deleteMoodReply = async (id: string) => {
  const params = {
    url: `${remoteApiUrl}/mood/comment/${id}`,
    options: {
      ...getHeaders(),
      method: "DELETE",
    },
    errorMsg: "Error deleting mood reply.",
  };
  return handleRequest(params);
};

const getMoodReplies = async (id: string) => {
  const params = {
    url: `${remoteApiUrl}/mood/${id}/comments`,
    options: {
      ...getHeaders(),
      method: "GET",
    },
    errorMsg: "Error getting mood replies.",
  };
  return handleRequest(params);
};

export {
  createMood,
  updateMood,
  getMoods,
  deleteMood,
  getMoodStats,
  getMoodsById,
  createMoodReply,
  updateMoodReply,
  deleteMoodReply,
  getMoodReplies,
};
