import React, { useEffect, useState } from "react";
import { Text, Box, Flex, VStack } from "@sqymagma/elements";
import { addMinutes, format, isBefore, isSameDay } from "date-fns";

import { useAuth, useApp } from "@contexts";
import { Button } from "@elements";
import { useModal } from "@hooks";
import { Modal, WidgetBox } from "@components";
import { CoffeeMatch } from "@types";
import { addPersonPhoto, findUserCoffee } from "@helpers";

import { AllCoffees, Participant } from "./atoms";
import * as S from "./style";

const es = require("date-fns/locale/es").default;

const VirtualCoffee = () => {
  const { user } = useAuth();
  const {
    coffees,
    people,
    config: { openVideoPopup },
  } = useApp();
  const [myCoffee, setMyCoffee] = useState<CoffeeMatch | null>(null);
  const { isOpen, toggleModal } = useModal(false);

  useEffect(() => {
    if (coffees && people.length) {
      const coffeeTime = new Date(coffees.date);
      const [hours, minutes] = coffees.hour ? coffees.hour.split(":") : [12, 0];
      coffeeTime.setHours(Number(hours));
      coffeeTime.setMinutes(Number(minutes));
      const now = new Date();
      const isCoffeeDay = isSameDay(now, coffeeTime);
      const isBeforeEnd = isBefore(now, addMinutes(coffeeTime, 30));
      if (isCoffeeDay && isBeforeEnd) {
        const matchedCoffee = findUserCoffee(coffees, user);
        if (matchedCoffee) {
          matchedCoffee.participants = matchedCoffee.participants.map(
            (participant) => addPersonPhoto(participant, people)
          );
          setMyCoffee(matchedCoffee);
        }
      } else {
        setMyCoffee(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coffees, people]);

  const participants = myCoffee?.participants.filter(
    (participant) => participant.email !== user?.email
  );

  const today =
    coffees?.date && format(new Date(coffees?.date), "PPPP", { locale: es });

  if (!myCoffee) return null;

  return (
    <>
      <WidgetBox mt={{ default: "xs", s: "m" }}>
        <VStack>
          <S.VirtualCoffee />
          <Box mb="xs" mt="xs">
            <Text textStyle="body" color="text01" weight="bold">
              Hoy tienes café con:
            </Text>
          </Box>
          <Flex justifyContent="space-around">
            {participants?.map((participant, index) => (
              <Participant key={index} participant={participant} />
            ))}
          </Flex>
          <S.ButtonsWrapper>
            <Button action={toggleModal} rightIcon="arrowRight2" animateHover>
              Ver cafés
            </Button>
            <Button
              action={() =>
                window.open(
                  myCoffee.chatroom,
                  "_blank",
                  openVideoPopup ? "popup" : undefined
                )
              }
              rightIcon="arrowRight2"
              animateHover
            >
              Ir a tu café
            </Button>
          </S.ButtonsWrapper>
        </VStack>
      </WidgetBox>
      <Modal
        isOpen={isOpen}
        hide={toggleModal}
        size="M"
        height="90%"
        title={`Virtual coffees del ${today}`}
      >
        <AllCoffees />
      </Modal>
    </>
  );
};

export default VirtualCoffee;
