import React from "react";
import { Flex, Text } from "@sqymagma/elements";
import sunflower from "@images/sunflower.svg";
import * as S from "./style";
import { Team, GeneralClassificationTeam } from "./utils";
import { WidgetBox } from "@components";

interface Props {
  teamsSteps?: Array<Team>;
  teamsGeneralClassification: Array<GeneralClassificationTeam>;
}

const Teams = (props: Props) => {
  const { teamsSteps, teamsGeneralClassification } = props;

  return (
    <>
      <Flex
        m="auto"
        width={{ default: "100%", xl: "90%", xxl: "80%" }}
        flexDirection={{ default: "column", xl: "row" }}
        mb="xxl"
      >
        {teamsSteps && !!teamsSteps.length && (
          <Flex flexDirection="column" mt="xl" mx="auto">
            <Flex pt="l" pl="xl">
              <Text textStyle="display02">Clasificación semanal</Text>
            </Flex>
            <Flex
              flexWrap="wrap"
              style={{ gap: "35px" }}
              justifyContent="flex-start"
              p="xl"
              mx="auto"
            >
              {teamsSteps.map((team, idx) => (
                <Flex
                  key={idx}
                  width="100%"
                  minWidth="100%"
                  flexGrow="2"
                  maxWidth="100%"
                  alignItems="center"
                >
                  <Text
                    textStyle="bodyLead"
                    color="text01"
                    width="20px"
                    textAlign="right"
                    mr="xs"
                  >
                    {idx + 1}
                  </Text>
                  <S.Flower src={sunflower} width="50px" />
                  <Flex flexDirection="column" ml="xs">
                    <Text mb="xxs" textStyle="display04" color="text01">
                      {team.name}
                    </Text>
                    <Text textStyle="body">
                      {Number(team.totalSteps).toLocaleString("es-ES")} pasos
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>
        )}
        {teamsGeneralClassification && !!teamsGeneralClassification.length && (
          <WidgetBox
            borderColor="line01"
            borderWidth={0}
            borderStyle="solid"
            mt="xl"
            style={{ padding: 0 }}
            mx="auto"
          >
            <Flex pt="l" pl="xl">
              <Text textStyle="display02">Clasificación general</Text>
            </Flex>
            <Flex
              flexWrap="wrap"
              style={{ gap: "35px" }}
              justifyContent="flex-start"
              p="xl"
              mx="auto"
            >
              {teamsGeneralClassification.map((team, idx) => (
                <Flex
                  key={idx}
                  width="100%"
                  minWidth="100%"
                  flexGrow="2"
                  maxWidth="100%"
                  alignItems="center"
                >
                  <Text
                    textStyle="bodyLead"
                    color="text01"
                    width="20px"
                    textAlign="right"
                    mr="xs"
                  >
                    {idx + 1}
                  </Text>
                  <S.Flower src={sunflower} width="50px" />
                  <Flex flexDirection="column" ml="s">
                    <Text mb="xxs" textStyle="display04" color="text01">
                      {team.name}
                    </Text>
                    <Text textStyle="body">
                      {Number(team.totalSteps).toLocaleString("es-ES")} pasos
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </WidgetBox>
        )}
      </Flex>
    </>
  );
};

export default Teams;
