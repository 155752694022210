import React, { useState } from "react";
import { VStack, HStack, Text, Flex, Box } from "@sqymagma/elements";
import { motion } from "framer-motion";

import { MoodReplyState } from "@types";
import { Avatar, Button, Editor, HTMLRender } from "@elements";
import { getPostTimeFromUnix, getUserDataFromEmail } from "@helpers";
import { useApp, useAuth } from "@contexts";
import { remoteApi } from "@services";
import { useModal } from "@hooks";
import { Modal } from "@components";

import * as S from "./style";

interface MoodReplyProps extends MoodReplyState {
  reloadReplies: () => void;
}

const MoodReply = (props: MoodReplyProps) => {
  const { id, created, message, user: moodUserEmail, reloadReplies } = props;

  const { people } = useApp();
  const { user } = useAuth();

  const [state, setState] = useState<string>(message);
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } =
    useModal(false);
  const { isOpen: isUpdateOpen, toggleModal: toggleUpdateModal } =
    useModal(false);

  const moodUser = getUserDataFromEmail(moodUserEmail, people);
  const isUserReply = moodUser?.email === user?.email;

  const handleDelete = async () => {
    toggleDeleteModal();
    const isDeleted = await remoteApi.deleteMoodReply(id);
    if (isDeleted) {
      reloadReplies();
    }
  };

  const handleUpdate = async () => {
    toggleUpdateModal();
    const isUpdated = await remoteApi.updateMoodReply(id, state);
    if (isUpdated) {
      reloadReplies();
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <S.Content bg="brand04" p="xs">
          <VStack gap="xs">
            {/* Header */}
            <Flex justifyContent="space-between">
              <HStack gap="xxs" alignItems="center">
                <Avatar
                  image={moodUser?.photo}
                  name={moodUser?.name || moodUserEmail}
                  size="s"
                />
                <Text
                  textStyle="body"
                  mr="xxs"
                  style={{ wordBreak: "break-all" }}
                  weight="bold"
                >
                  {moodUser?.name || moodUserEmail}
                </Text>
                <Text textStyle="subtitle03" color="text01">
                  {getPostTimeFromUnix(created._seconds)}
                </Text>
              </HStack>
              {isUserReply && (
                <HStack gap="xxxs">
                  <Button
                    appearance="primary"
                    leftIcon="edit"
                    action={toggleUpdateModal}
                    tooltip="Modificar"
                    rounded
                  />
                  <Button
                    appearance="primary"
                    leftIcon="trash"
                    action={toggleDeleteModal}
                    tooltip="Eliminar"
                    rounded
                  />
                </HStack>
              )}
            </Flex>
            {/* Message */}
            <HTMLRender>{message}</HTMLRender>
          </VStack>
        </S.Content>
      </motion.div>

      {/* Delete modal */}
      <Modal
        isOpen={isDeleteOpen}
        hide={toggleDeleteModal}
        size="S"
        title="Eliminar respuesta"
        isChild={true}
        mainAction={{
          title: "Eliminar",
          action: handleDelete,
        }}
        secondaryAction={{ title: "Cancelar", action: toggleDeleteModal }}
      >
        <Box py="s" px="xs">
          <Text textStyle="body" color="text01">
            ¿Seguro que quieres eliminar esta respuesta?
          </Text>
        </Box>
      </Modal>

      {/* Update modal */}
      <Modal
        isOpen={isUpdateOpen}
        hide={toggleUpdateModal}
        title="Modifica la respuesta"
        size="S"
        height="auto"
        isChild={true}
        allowOverflow
        mainAction={{
          title: "Aceptar",
          action: handleUpdate,
        }}
        secondaryAction={{ title: "Cancelar", action: toggleUpdateModal }}
      >
        <Box p="s" mt="xxs">
          <Editor value={state} onChange={setState} buttons={["youtube"]} />
        </Box>
      </Modal>
    </>
  );
};

export default MoodReply;
