import React, { useMemo, useState } from "react";
import { VStack, HStack, Text, Flex, Box } from "@sqymagma/elements";
import { motion } from "framer-motion";

import { MoodState } from "@types";
import { Modal, MoodWidget, WidgetBox } from "@components";
import { Avatar, Button, Icon, HTMLRender, Tooltip } from "@elements";
import { IconNames } from "@elements/Icon";
import { getPostTimeFromUnix, getUserDataFromEmail } from "@helpers";
import { useApp, useAuth, useToast } from "@contexts";
import { useModal } from "@hooks";
import { remoteApi } from "@services";

import MoodReplyModal from "./MoodReplyModal";
import * as S from "./style";

interface MoodCardProps extends MoodState {
  reloadMoods: () => void;
  fromNotification?: boolean;
}

const moodIcons: Array<IconNames> = [
  "reallyBadMoodOn",
  "badMoodOn",
  "neutralMoodOn",
  "happyMoodOn",
  "reallyHappyMoodOn",
];

const MoodCard = (props: MoodCardProps) => {
  const { reloadMoods, fromNotification, ...moodData } = props;
  const {
    id,
    created,
    hidden,
    message,
    mood,
    user: moodUserEmail,
    comments,
  } = moodData;

  const { people } = useApp();
  const { user } = useAuth();
  const [repliesCount, setRepliesCount] = useState(comments?.length || 0);
  const { addToast } = useToast();

  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } =
    useModal(false);
  const { isOpen: isUpdateOpen, toggleModal: toggleUpdateModal } =
    useModal(false);
  const { isOpen: isCommentsOpen, toggleModal: toggleCommentsModal } = useModal(
    !!fromNotification
  );

  const moodUser = getUserDataFromEmail(moodUserEmail, people);
  const isUserMood = moodUser?.email === user?.email;

  const handleDelete = async () => {
    toggleDeleteModal();
    const isDeleted = await remoteApi.deleteMood(id);
    if (isDeleted) {
      reloadMoods();
      addToast("Mensaje eliminado");
    } else {
      addToast("⚠️ ¡Oops! No se pudo eliminar el mensaje");
    }
  };

  const handleOnUpdate = async () => {
    toggleUpdateModal();
    reloadMoods();
  };

  const moodContent = useMemo(
    () => (
      <VStack gap="xs">
        {/* Header */}
        <Flex justifyContent="space-between">
          <HStack gap="xxs" alignItems="center">
            <Avatar
              image={moodUser?.photo}
              name={moodUser?.name || moodUserEmail}
            />
            <VStack>
              <Text
                textStyle="subtitle01"
                mr="xxs"
                style={{ fontWeight: "bold", wordBreak: "break-all" }}
              >
                {moodUser?.name || moodUserEmail}
              </Text>
              <Text textStyle="bodyInline" color="text01">
                {getPostTimeFromUnix(created._seconds)}
              </Text>
            </VStack>
          </HStack>
          <Icon name={moodIcons[mood - 1]} width={55} height={55} />
        </Flex>
        {/* Message */}
        {message && <HTMLRender>{message}</HTMLRender>}
      </VStack>
    ),
    [
      created._seconds,
      message,
      mood,
      moodUser?.name,
      moodUser?.photo,
      moodUserEmail,
    ]
  );

  return (
    <>
      {fromNotification ? null : (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <WidgetBox
            m="xxs"
            bg={isUserMood ? "brand06" : null}
            borderColor="line01"
            borderWidth={hidden && !isUserMood ? 1 : 0}
            borderStyle="solid"
          >
            <VStack gap="xs">
              {/* Hidden mood badge */}
              {hidden && (
                <Flex justifyContent="center">
                  <Icon name="lock" size="small" color="text01" />
                </Flex>
              )}

              {moodContent}

              {/* Footer */}
              <Flex>
                <Tooltip content="Respuestas" hideOnClick>
                  <S.RepliesWrapper gap="xxxs" onClick={toggleCommentsModal}>
                    <Icon name="comment" width={20} height={20} />
                    <Text textStyle="button" color="text01">
                      {repliesCount > 0 ? repliesCount : ""}
                    </Text>
                  </S.RepliesWrapper>
                </Tooltip>
                {isUserMood && (
                  <HStack justifyContent="flex-end" gap="xxs" ml="auto">
                    <HStack gap="xxxs">
                      <Button
                        appearance="tertiary"
                        leftIcon="edit"
                        action={toggleUpdateModal}
                        tooltip="Modificar"
                        rounded
                      />
                      <Button
                        appearance="tertiary"
                        leftIcon="trash"
                        action={toggleDeleteModal}
                        tooltip="Eliminar"
                        rounded
                      />
                    </HStack>
                  </HStack>
                )}
              </Flex>
            </VStack>
          </WidgetBox>
        </motion.div>
      )}

      {/* Delete modal */}
      <Modal
        isOpen={isDeleteOpen}
        hide={toggleDeleteModal}
        size="S"
        title="Eliminar estado de ánimo"
        mainAction={{
          title: "Eliminar",
          action: handleDelete,
        }}
        secondaryAction={{ title: "Cancelar", action: toggleDeleteModal }}
      >
        <Box py="s" px="xs">
          <Text textStyle="body" color="text01">
            ¿Seguro que quieres eliminar este estado de ánimo?
          </Text>
        </Box>
      </Modal>

      {/* Update modal */}
      <Modal
        isOpen={isUpdateOpen}
        hide={toggleUpdateModal}
        title="Modifica el estado de ánimo"
        size="S"
        height="auto"
        allowOverflow
      >
        <MoodWidget update={moodData} onSubmit={handleOnUpdate} />
      </Modal>

      {isCommentsOpen && (
        <MoodReplyModal
          isOpen={isCommentsOpen}
          toggleModal={toggleCommentsModal}
          moodContent={moodContent}
          id={id}
          repliesCount={repliesCount}
          setRepliesCount={setRepliesCount}
          fromNotification={fromNotification}
        />
      )}
    </>
  );
};

export default MoodCard;
