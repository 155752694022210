import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { VStack, Flex, Text, Box } from "@sqymagma/elements";

import { MoodPayload, MoodState } from "@types";
import { Button, Editor, Icon, IconLink, Toggle } from "@elements";
import { IconNames } from "@elements/Icon";
import { WidgetBox } from "@components";
import { remoteApi } from "@services";
import { darkenHexColor, getRandom } from "@helpers";
import { useStyle, useToast } from "@contexts";
import { THEMES } from "@constants";

import { successMsg, privateSuccess } from "./data";
import * as S from "./style";

interface MoodWidgetProps {
  onSubmit?: () => void;
  update?: MoodState;
}

const MoodWidget = (props: MoodWidgetProps) => {
  const { onSubmit, update } = props;

  const { addToast } = useToast();

  const { theme } = useStyle();
  const isDarkTheme = theme === THEMES.dark;

  const initialState = {
    mood: update?.mood || null,
    hidden: update?.hidden || false,
    message: update?.message || "",
  };
  const [state, setState] = useState<MoodPayload>(initialState);
  const [isSending, setIsSending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const history = useHistory();

  const handleAction = () => {
    history.push("/mood");
  };

  const handleEditorChange = (content: string) =>
    setState((state) => ({ ...state, message: content }));

  const handleCreate = async () => {
    setIsSending(true);
    const createOk = await remoteApi.createMood(state);
    if (createOk) {
      setIsSuccess(true);
    }
    setIsSending(false);
    onSubmit && onSubmit();
  };

  const handleUpdate = async () => {
    setIsSending(true);
    const updateOk = update && (await remoteApi.updateMood(update.id, state));
    if (updateOk) {
      addToast("Mensaje actualizado");
    } else {
      addToast("⚠️ ¡Oops! Error al actualizar el mensaje");
    }
    setIsSending(false);
    onSubmit && onSubmit();
  };

  const handleClick = (mood: number) => () => {
    const text = getRandom(successMsg[mood - 1].text);
    setState((state) => ({ ...state, mood }));
    setMessage(text);
  };

  const handleChange = () =>
    setState((state) => ({ ...state, hidden: !state.hidden }));

  const MoodIcon = useCallback(
    (props: { icon: IconNames; active: boolean; onClick: () => void }) => {
      const { icon, active, onClick } = props;
      const iconName = active ? (`${icon}On` as IconNames) : icon;
      return (
        <S.IconWrapper onClick={onClick}>
          <Icon name={iconName} width={50} height={50} />
        </S.IconWrapper>
      );
    },
    []
  );

  const allowSend = !!state.mood && !isSending;

  const placeholder =
    "Cuéntanos un poquito más dejando un comentario personalizado.";

  const successMsgColor =
    state.mood &&
    (isDarkTheme
      ? darkenHexColor(successMsg[state.mood - 1].color, 0.7)
      : successMsg[state.mood - 1].color);

  return (
    <WidgetBox $noShadow={!!update}>
      <VStack gap="xxs">
        {!update && (
          <Flex justifyContent="space-between" alignItems="center" mb="xs">
            <Text textStyle="subtitle01" color="text01" weight="bold">
              ¿Cómo te sientes hoy?
            </Text>
            <IconLink
              icon="barChart"
              to="/mood"
              tooltip="Timeline y estadísticas"
            />
          </Flex>
        )}

        {isSuccess && state.mood ? (
          <>
            <S.SuccessBox bg={successMsgColor} p="xs" mt="xxs">
              <Text textStyle="body" color="text01">
                {state.hidden ? privateSuccess : message}
              </Text>
            </S.SuccessBox>
            <Flex justifyContent="flex-end" mt="xs">
              <Button
                rightIcon="arrowRight2"
                action={handleAction}
                animateHover
              >
                Ver pulso del equipo
              </Button>
            </Flex>
          </>
        ) : (
          <>
            <Flex justifyContent="space-between" mb="xs">
              <MoodIcon
                icon="reallyBadMood"
                active={state.mood === 1}
                onClick={handleClick(1)}
              />
              <MoodIcon
                icon="badMood"
                active={state.mood === 2}
                onClick={handleClick(2)}
              />
              <MoodIcon
                icon="neutralMood"
                active={state.mood === 3}
                onClick={handleClick(3)}
              />
              <MoodIcon
                icon="happyMood"
                active={state.mood === 4}
                onClick={handleClick(4)}
              />
              <MoodIcon
                icon="reallyHappyMood"
                active={state.mood === 5}
                onClick={handleClick(5)}
              />
            </Flex>

            <Box>
              <Editor
                value={state.message}
                onChange={handleEditorChange}
                placeholder={placeholder}
                buttons={["youtube"]}
              />

              <Flex justifyContent="flex-end" alignItems="center">
                <Text textStyle="bodyInline" color="darkgray">
                  Utilizar el canal privado
                </Text>
                <Toggle
                  name="hideToggle"
                  value={state.hidden}
                  onChange={handleChange}
                  size="s"
                />
              </Flex>
            </Box>

            <Flex justifyContent="flex-end" mt="xs">
              <Button
                rightIcon="arrowRight2"
                disabled={!allowSend}
                action={update ? handleUpdate : handleCreate}
                animateHover
              >
                {update ? "Actualizar" : "Enviar"}
              </Button>
            </Flex>
          </>
        )}
      </VStack>
    </WidgetBox>
  );
};

export default MoodWidget;
