import React, { useRef } from "react";
import { AnimatePresence } from "framer-motion";
import { Box } from "@sqymagma/elements";

import { useApp, useAuth, useStyle } from "@contexts";
import { useModal } from "@hooks";
import { THEMES } from "@constants";

import { ReactComponent as Logo } from "@icons/logo.svg";
import { ReactComponent as LogoInverted } from "@icons/logo-inverted.svg";

import Menu from "./Menu";
import * as S from "./style";

const MobileNavBar = () => {
  const auth = useAuth();
  const { user } = auth;
  const {
    serviceWorker: { showReload },
  } = useApp();
  const { theme } = useStyle();

  const { isOpen, toggleModal } = useModal();
  const wrapperRef = useRef();

  const isDarkTheme = theme === THEMES.dark;

  return (
    <Box
      display={{ default: "block", s: "none" }}
      position="sticky"
      zIndex={1}
      top={0}
      ref={wrapperRef}
    >
      <S.Wrapper>
        <S.MenuButton
          className={isOpen ? "open" : "closed"}
          onClick={toggleModal}
        >
          <span></span>
          {showReload && <S.Badge />}
        </S.MenuButton>
        <S.Logo to="/" onClick={() => isOpen && toggleModal()}>
          {isDarkTheme ? (
            <LogoInverted width="40" height="40" />
          ) : (
            <Logo width="40" height="40" />
          )}
          <S.AppName ml="xxxs" mt={4}>
            remote
          </S.AppName>
        </S.Logo>
        {user && <S.Picture src={user.image} alt={user.name} />}
      </S.Wrapper>
      <AnimatePresence>
        {isOpen && <Menu toggleModal={toggleModal} />}
      </AnimatePresence>
    </Box>
  );
};

export default MobileNavBar;
