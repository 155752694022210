import styled from "styled-components";
import { HStack } from "@sqymagma/elements";

export const RepliesWrapper = styled(HStack)`
  cursor: pointer;
  svg {
    path,
    circle {
      fill: ${(p) => p.theme.colors.text01};
    }
  }

  &:hover {
    span {
      color: ${(p) => p.theme.colors.interactive01Hover};
    }
    svg {
      path,
      circle {
        fill: ${(p) => p.theme.colors.interactive01Hover};
      }
    }
  }
`;
