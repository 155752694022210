import React, { useState, useEffect } from "react";
import { VStack, Flex, Text } from "@sqymagma/elements";

import { Applause, ApplausePayload } from "@types";
import { useApp, useToast } from "@contexts";
import { Button, Editor } from "@elements";
import { Animation, WidgetBox } from "@components";
import { remoteApi } from "@services";

import PeopleSelector from "./PeopleSelector";
import ClapsSelector from "./ClapsSelector";
import * as S from "./style";

export type SettingsProps = Omit<ApplausePayload, "message">;

interface ApplauseWidgetProps {
  actionElements?: JSX.Element;
  onSubmit?: () => void;
  update?: Applause;
}

const ApplauseWidget = (props: ApplauseWidgetProps) => {
  const { actionElements, onSubmit, update } = props;

  const { getRemainingClaps } = useApp();
  const { addToast } = useToast();

  const [editorContent, setEditorContent] = useState(update?.message || "");

  const initialSettings = {
    emails: update?.emails || [],
    claps: update?.claps || 0,
  };
  const [settings, setSettings] = useState<SettingsProps>(initialSettings);

  const [showAnimation, setShowAnimation] = useState(false);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    getRemainingClaps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditorChange = (content: string) => {
    setEditorContent(content);
  };

  const handleCreate = async () => {
    setIsSending(true);
    const payload = { ...settings, message: editorContent };
    const createOk = await remoteApi.createApplause(payload);
    if (createOk) {
      setSettings(initialSettings);
      setEditorContent("");
      addToast("¡Perfecto! Aplausos enviados 👏");
      setShowAnimation(true);
    } else {
      addToast("⚠️ ¡Oops! Error al enviar los aplausos");
    }
    setIsSending(false);
    getRemainingClaps();
    onSubmit && onSubmit();
  };

  const handleUpdate = async () => {
    setIsSending(true);
    const payload = { ...settings, message: editorContent };
    const updateOk =
      update && (await remoteApi.updateApplause(update.id, payload));
    if (updateOk) {
      addToast("Aplauso actualizado");
    } else {
      addToast("⚠️ ¡Oops! Error al actualizar el aplauso");
    }
    setIsSending(false);
    getRemainingClaps();
    onSubmit && onSubmit();
  };

  const allowSend = !!settings.emails.length && !!settings.claps && !isSending;

  return (
    <WidgetBox position="relative" $noShadow={!!update}>
      <VStack gap="xxs">
        {(!update || actionElements) && (
          <Flex justifyContent="space-between" alignItems="center">
            <Text textStyle="subtitle01" color="text01" weight="bold">
              {!update && "Envía un reconocimiento"}
            </Text>
            {actionElements}
          </Flex>
        )}

        <S.Selectors>
          <PeopleSelector settings={settings} setSettings={setSettings} />
          <ClapsSelector
            settings={settings}
            setSettings={setSettings}
            update={update}
          />
        </S.Selectors>

        <Editor value={editorContent} onChange={handleEditorChange} />

        <Flex justifyContent="flex-end" mt="xxs">
          <Button
            rightIcon="arrowRight2"
            disabled={!allowSend}
            action={update ? handleUpdate : handleCreate}
            animateHover
          >
            {update ? "Actualizar" : "Enviar"}
          </Button>
        </Flex>
      </VStack>

      <S.Animation>
        <Animation
          name="applause"
          duration={3}
          animate={showAnimation}
          onFinish={() => setShowAnimation(false)}
          width="320px"
          height="300px"
        />
      </S.Animation>
    </WidgetBox>
  );
};

export default ApplauseWidget;
