import { Box, Button as MagmaButton } from "@sqymagma/elements";
import styled, { css } from "styled-components";
import { theme } from "@sqymagma/theme";

const StyledButton = styled(MagmaButton)`
  padding: ${(p) => p.theme.spacing.xxxs};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  background: transparent;
  border: none;
  transition: background-color ${(p) => p.theme.timing.hover};
  box-shadow: ${(p) => (p.shadow ? p.theme.shadows.small : "none")};

  ${(p) => p.appearance === "secondary" && Secondary}
  ${(p) => p.appearance === "tertiary" && Tertiary}

  &:focus {
    outline: none;
  }

  &:hover {
    ${(p) =>
      !p.disabled &&
      p.$animateHover &&
      css`
        & .left-icon svg {
          transform: ${(p) => `translateX(-${p.theme.spacing.xxs})`};
        }
        & .right-icon svg {
          transform: ${(p) => `translateX(${p.theme.spacing.xxs})`};
        }
      `}
  }

  &:hover {
    ${(p) =>
      !p.$animateHover &&
      p.appearance === "primary" &&
      css`
        span {
          color: ${(p) => p.theme.colors.interactive01Hover};
        }
        svg {
          fill: ${(p) => p.theme.colors.interactive01Hover};
        }
      `}
  }

  svg {
    transition: transform 0.33s cubic-bezier(0.8, 0, 0.2, 1);
  }

  &:active {
    outline: none;
    box-shadow:
      0 0 0 0 transparent,
      0 0 0 0 transparent;
    color: ${theme("colors.textOnInteractive01Pressed")};
  }
`;

const IconPlace = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Span = styled.span<{ disabled: boolean }>`
  ${(p) => p.theme.textStyle.button}
  color: ${(p) =>
    p.color ? theme(`color.${p.color}`) : theme("colors.textOnInteractive01")};
  ${(p) =>
    p.disabled &&
    css`
      color: ${theme("colors.disabled01")};
    `}
`;

const Secondary = css<{ rounded?: boolean }>`
  background-color: ${theme("colors.secondaryBackground")};
  padding: ${(p) =>
    `${p.theme.spacing.buttonS.paddingY} ${p.theme.spacing.buttonS.paddingX}`};
  border-radius: ${(p) => (p.rounded ? p.theme.radii.l : p.theme.radii.s)};

  &:hover {
    background-color: ${(p) => p.theme.colors.interactive01Hover};
  }

  & ${Span} {
    color: ${theme("colors.text02")};
  }
`;

const Tertiary = css<{ rounded?: boolean }>`
  padding: ${(p) => p.theme.spacing.xxxs};
  border-radius: ${(p) => (p.rounded ? p.theme.radii.l : p.theme.radii.s)};
  border: 1px solid ${(p) => p.theme.colors.line01};
  background-color: ${(p) => p.theme.colors.quinaryBackground};
  mix-blend-mode: luminosity;

  &:hover {
    background-color: ${(p) => `${p.theme.colors.interactive01Hover}88`};
    border: 1px solid ${(p) => p.theme.colors.interactive01Hover};
    mix-blend-mode: normal;
  }

  & ${Span} {
    ${(p) => p.theme.textStyle.buttonM}
  }
`;

export { StyledButton, IconPlace, Span };
