import { Person, User } from "@types";
import { WRAPPED_STEPS } from "./constants";
import { remoteApi, googleApi } from "@services";
import { formatClapsList } from "./Claps/utils";
import { ClapsProps } from "./Claps";
import { CamelsProps, UserSteps } from "./Camels";
import moment from "moment";
import { handleRequest } from "@helpers";
require("moment-precise-range-plugin");
require('moment');

const getWrappedDates = () => {
  const wrappedDay = 18;
  const today = new Date();
  let start: Date;
  let end: Date;

  if (today.getMonth() < 11 || today.getDate() < wrappedDay) {
    start = new Date(`12/${wrappedDay}/${today.getFullYear() - 2}`);
    end = new Date(`12/${wrappedDay}/${today.getFullYear() - 1}`);
  } else {
    start = new Date(`12/${wrappedDay}/${today.getFullYear() - 1}`);
    end = new Date(`12/${wrappedDay}/${today.getFullYear()}`);
  }

  return { start, end };
};

const getWrappedSteps = (
  clapsProps?: ClapsProps,
  camelsProps?: CamelsProps
) => {
  return WRAPPED_STEPS.map((value) => {
    let childrenKey: "children" | "childrenNoData" = "children";
    if (value.step === "Claps" && !clapsProps?.received) {
      childrenKey = "childrenNoData";
    }

    if (
      value.step === "Camels" &&
      camelsProps?.totalYearSteps &&
      camelsProps?.totalYearSteps === 0
    ) {
      childrenKey = "childrenNoData";
    }

    return (
      value[childrenKey]?.reduce((prev, current) => prev + current * 1000, 0) ||
      0
    );
  });
};

const getUserClaps = async (start: Date, end: Date, people: Array<Person>) => {
  const result: ClapsProps = {};

  const claps = await remoteApi.getLastYearApplauses(start, end);

  if (claps?.received) {
    let received = 0;
    const receivedByUser: { [key: string]: number } = {};
    claps.received.forEach(({ claps, user }) => {
      received += claps;
      if (receivedByUser[user.email]) {
        receivedByUser[user.email] += claps;
      } else {
        receivedByUser[user.email] = claps;
      }
    });

    result.received = received;
    result.receivedList = formatClapsList(receivedByUser, people);
  }
  if (claps?.sent) {
    let sent = 0;
    const sentToUser: { [key: string]: number } = {};
    claps.sent.forEach(({ claps, emails }) => {
      sent += claps;

      emails.forEach((user) => {
        if (sentToUser[user]) {
          sentToUser[user] += claps;
        } else {
          sentToUser[user] = claps;
        }
      });
    });

    result.sent = sent;
    result.sentList = formatClapsList(sentToUser, people);
  }

  return result;
};

const getUserGithub = async (start: Date, end: Date, user: User) => {
  const email = user.email

  const params = {
    url: `https://api.github.com/repos/Secuoyas-Experience/ie-components/stats/contributors`
  }
  const stats = await handleRequest(params);
  return stats;
}

const getUserSteps = async (start: Date, end: Date, user: User) => {
  const result: CamelsProps = {};

  const { REACT_APP_CAMELS_GSHEET_ID: spreadsheetId } = process.env;
  if (!spreadsheetId) return;
  const payload = { spreadsheetId, range: "Carreras!A1:ZZ100" };
  const rows = await googleApi.getSheet(payload);
  if (rows) {
    const dates = rows[0];

    const startDate = moment(start);
    const endDate = moment(end);

    const wrappedDates = dates
      .slice(1)
      .filter(date => moment(date).isSameOrAfter(startDate))
      .filter(date => moment(date).isSameOrBefore(endDate))
    
    const filterStartDateByIndex = dates.findIndex(date => date === wrappedDates[0])
    const filterEndDateByIndex = dates.findIndex((date) => date === wrappedDates[wrappedDates.length-1]);

    const rowMatchUser = rows.find((value) => value[0] === user?.email);

    if (rowMatchUser) {
      const rowMatchUserSliced = rowMatchUser.slice(
      filterStartDateByIndex === 0 ? 1 : filterStartDateByIndex+1,
      filterEndDateByIndex+1
      );
      const totalSteps = rowMatchUserSliced.reduce((prev, curr) => Number(prev) + Number(curr), 0);
      const userSteps: UserSteps[] = [];
      const datesSliced = dates.slice(
      filterStartDateByIndex === 0 ? 1 : filterStartDateByIndex+1,
      filterEndDateByIndex+1
      );
      rowMatchUserSliced.forEach((cell, i) => {
        userSteps.push({
          date: datesSliced[i],
          steps: rowMatchUserSliced[i],
        });
      });
      result.totalYearSteps = totalSteps;
      result.topSteps = userSteps
        .sort((a, b) => {
          if (Number(a.steps) < Number(b.steps)) {
            return 1;
          } else if (Number(a.steps) > Number(b.steps)) {
            return -1;
          }
          return 0;
        })
        .slice(0, 3);
    } else {
      result.totalYearSteps = 0;
    }
  }
  return result;
};

export { getWrappedDates, getWrappedSteps, getUserClaps, getUserSteps };
