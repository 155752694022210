const GROUPS = [
  {
    name: "Carmen, Charlie, Enrique & Olga",
    participants: [
      "carlos.denova@secuoyas.com",
      "enrique.sanz@secuoyas.com",
      "olga.casajuana@secuoyas.com",
      "carmen.martin@secuoyas.com",
    ],
    camelSteps: [],
    totalSteps: 0,
  },
  {
    name: "Clara, Gloria & Majo D.",
    participants: [
      "gloria.rodrigo@secuoyas.com",
      "majo.dejuan@secuoyas.com",
      "clara.iglesias@secuoyas.com",
    ],
    camelSteps: [],
    totalSteps: 0,
  },
  {
    name: "Alba, Ana G. & Maite",
    participants: [
      "alba.lopez@secuoyas.com",
      "maite.antolin@secuoyas.com",
      "ana.gonzalez@secuoyas.com",
    ],
    camelSteps: [],
    totalSteps: 0,
  },
  {
    name: "Fer, Gonzalo H. & Diana",
    participants: [
      "gonzalo.hernandez@secuoyas.com",
      "fernando.demorais@secuoyas.com",
      "diana.hidalgo@secuoyas.com",
    ],
    camelSteps: [],
    totalSteps: 0,
  },
  {
    name: "Ana, Jorge & Majo N.",
    participants: [
      "jorge.fernandez@secuoyas.com",
      "ana.jaime@secuoyas.com",
      "mariajose.noriega@secuoyas.com",
    ],
    camelSteps: [],
    totalSteps: 0,
  },
  {
    name: "Álvaro, Luisa & Sara",
    participants: [
      "luisa.rodriguez@secuoyas.com",
      "sara.regueira@secuoyas.com",
      "alvaro.sanchez@secuoyas.com",
    ],
    camelSteps: [],
    totalSteps: 0,
  },
];

export { GROUPS };
