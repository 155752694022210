import React from "react";
import { format } from "date-fns";
import { capitalizeFirstLetter } from "@helpers";
import { Rewards, User } from "@types";

const es = require("date-fns/locale/es").default;

const getToday = () => {
  const date = new Date();
  const dayOfWeek = format(date, "EEEE", { locale: es });
  const month = format(date, "MMMM", { locale: es });
  const day = format(date, "d", { locale: es });
  const today = `${capitalizeFirstLetter(
    dayOfWeek
  )}, ${day} de ${month.toLowerCase()}`;
  return today;
};

const getGreeting = (rewardType: Rewards, user: User | null) => {
  return rewardType === "secuoyasBirthday" ? (
    <>
      ¡Feliz cumpleaños, <strong>Secuoyas</strong>!
    </>
  ) : (
    <>
      ¡Hola de nuevo, <strong>{user?.givenName}</strong>!
    </>
  );
};

export { getToday, getGreeting };
