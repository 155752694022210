import { MeetingId } from "@data/meetings";
import { getMonthDates } from "@helpers";
import {
  Coffees,
  Config,
  Email,
  Event,
  Holiday,
  Person,
  Rewards,
  Vacation,
  Weather,
} from "@types";

export type AppState = {
  time: Date;
  lastUpdate: Date | null;
  weather: Weather | null;
  holidays: Holiday[];
  vacations: Vacation[];
  selectedDay: Date;
  selectedDayEvents: Event[];
  todayEvents: Event[];
  selectedMonth: { firstDate: Date; lastDate: Date };
  monthEvents: Event[];
  emails: Email[];
  numberOfEmails: number;
  people: Person[];
  recentTools: string[];
  meetings: MeetingId[] | null;
  coffees: Coffees | null;
  shortUrls: string[];
  rewardType: Rewards;
  config: Config;
  applauses: {
    remainingClaps: number;
  };
  moodsWithNewComments: Array<string>;
};

export const initialState: AppState = {
  time: new Date(),
  lastUpdate: null,
  weather: null,
  holidays: [],
  vacations: [],
  selectedDay: new Date(),
  selectedDayEvents: [],
  todayEvents: [],
  selectedMonth: getMonthDates(new Date()),
  monthEvents: [],
  emails: [],
  numberOfEmails: 5,
  people: [],
  recentTools: [],
  meetings: null,
  coffees: null,
  shortUrls: [],
  rewardType: null,
  config: {
    // default app settings
    openVideoPopup: false,
    notifyEvents: true,
    applausesReminder: true,
  },
  applauses: {
    remainingClaps: 0,
  },
  moodsWithNewComments: [],
};

export const reducer = (state: AppState, payload: Partial<AppState>) => ({
  ...state,
  ...payload,
});
