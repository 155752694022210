import React from "react";
import { Text, VStack, Box } from "@sqymagma/elements";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import konstelacion from "@images/meeting-konstelacion.jpg";
import katla from "@images/meeting-katla.jpg";
import kilauea from "@images/meeting-kilauea.jpg";
import kilimanjaro from "@images/meeting-kilimanjaro.jpg";
import krakatoa from "@images/meeting-krakatoa.jpg";
import kadovar from "@images/meeting-kadovar.jpg";
import karymsky from "@images/meeting-karymsky.jpg";
import katete from "@images/meeting-katete.jpg";

import { Meeting } from "@types";
import { Icon } from "@elements";

import * as S from "./style";

const images = {
  konstelacion,
  katla,
  kilauea,
  kilimanjaro,
  krakatoa,
  kadovar,
  karymsky,
  katete,
};

const MeetingBox = (props: Props) => {
  const { meeting, tiny, disabled, dragHandleProps } = props;
  const { id, icon, title, description } = meeting;

  return (
    <S.MeetingBox
      image={images[id]}
      $tiny={tiny}
      disabled={disabled}
      {...dragHandleProps}
    >
      <div>
        {icon && <Icon name={icon} size={tiny ? "small" : "large"} />}
        <VStack textAlign="center">
          <Box>
            <Text
              textStyle={tiny ? "body" : "subtitle01"}
              color="light"
              weight="bold"
            >
              {title}
            </Text>
          </Box>
          {!tiny && (
            <Box>
              <S.Description>{description}</S.Description>
            </Box>
          )}
        </VStack>
      </div>
    </S.MeetingBox>
  );
};

interface Props {
  meeting: Meeting;
  tiny?: boolean;
  disabled?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
}

export default MeetingBox;
