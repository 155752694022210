import styled, { css } from "styled-components";
import { Text } from "@sqymagma/elements";

const MeetingBox = styled.div<{
  $tiny?: boolean;
  disabled?: boolean;
  image: string;
}>`
  border-radius: ${(p) => (p.$tiny ? p.theme.radii.m : p.theme.radii.l)};
  box-shadow: ${(p) => (p.$tiny ? "none" : p.theme.shadows.small)};
  padding: ${(p) => (p.$tiny ? p.theme.spacing.xxs : p.theme.spacing.xs)};
  cursor: ${(p) => (p.disabled ? "inherit" : "pointer")};
  position: relative;
  overflow: hidden;
  background-color: ${(p) => p.theme.colors.dark};
  height: ${(p) => (p.$tiny ? "90px" : "200px")};

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: ${(p) => `url(${p.image})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    transition: transform ${(p) => p.theme.timing.s};
  }

  &:hover:before {
    ${(p) =>
      !p.disabled &&
      css`
        transform: scale(1.1);
      `}
  }

  & > div {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

const Description = styled(Text)`
  ${(p) => p.theme.textStyle.body};
  color: ${(p) => p.theme.colors.light};
  word-break: break-all;
`;

export { MeetingBox, Description };
