module.exports = {
  id: "global-theme",

  color: {
    colors: {
      light: { value: "#ffffff" },
      dark: { value: "#101010" },
      lightgray: { value: "#f5f5f5" },
      gray: { value: "#e9e9e9" },
      darkgray: { value: "#9c9c9c" },
      gold: { value: "#bea382" },

      calendar1: { value: "#3cc3dd" },
      calendar2: { value: "#fa0067" },
      holidays: { value: "#07C592" },
      vacations: { value: "#00ABF5" },

      error: { value: "#f3d8da" },
      success: { value: "#5ABF73" },
      info: { value: "#3CC3DD" },
      important: { value: "#ec5445" },
      warning: { value: "#fdf8eb" },

      green: { value: "#186A3B" },
      red: { value: "#DE3163" },
      amber: { value: "#FE7E26" },

      overlay: {
        type: "RGB",
        value: ["16", "16", "16"],
        opacity: "0.5",
      },
      overlayLight: { value: "#ffffff", opacity: 0.5 },
    },
  },

  fontFamily: {
    fontFamilies: {
      primary: "'Gilroy', sans-serif",
    },
  },

  mediaquery: {
    description: "",
    mediaqueries: [
      {
        label: "default",
        minWidth: null,
        container: "100%",
        sideMargin: "100px",
      },
      {
        label: "s",
        minWidth: "600px",
        container: "400px",
        sideMargin: "100px",
      },
      {
        label: "m",
        minWidth: "768px",
        container: "568px",
        sideMargin: "100px",
      },
      {
        label: "l",
        minWidth: "1024px",
        container: "800px",
        sideMargin: "100px",
      },
      {
        label: "xl",
        minWidth: "1216px",
        container: "1000px",
        sideMargin: "100px",
      },
      {
        label: "xxl",
        minWidth: "1542px",
        container: "1342px",
        sideMargin: "100px",
      },
      {
        label: "xxxl",
        minWidth: "1680px",
        container: "1480px",
        sideMargin: "100px",
      },
    ],
  },

  fontWeight: {
    thin: "100",
    light: "300",
    normal: "400",
    bold: "700",
    extraBold: "800",
  },

  lineHeight: {
    s: 1,
    m: 1.2,
    l: 1.5,
    xl: 3,
  },

  textStyle: {
    defaultStyles: {
      fontWeight: "400",
      lineHeight: "1.4",
      letterSpacing: "0px",
      fontSize: "10px",
      fontFamily: "primary",
    },
    textStyles: [
      {
        name: "display01",
        fontFamily: "primary",
        fontSize: "56px",
        lineHeight: "120%",
        fontWeight: "400",
        responsive: [],
      },
      {
        name: "display02",
        fontFamily: "primary",
        fontSize: "26px",
        lineHeight: "120%",
        fontWeight: "400",
        responsive: [
          {
            breakpoint: "xl",
            fontSize: "44px",
          },
          {
            breakpoint: "s",
            fontSize: "36px",
          },
        ],
      },
      {
        name: "display03",
        fontFamily: "primary",
        fontSize: "26px",
        lineHeight: "120%",
        fontWeight: "400",
        responsive: [],
      },
      {
        name: "display04",
        fontFamily: "primary",
        fontSize: "20px",
        lineHeight: "130%",
        fontWeight: "700",
        responsive: [],
      },
      {
        name: "headingxxl",
        fontFamily: "primary",
        fontSize: "32px",
        lineHeight: "135%",
        fontWeight: "700",
        responsive: [
          {
            breakpoint: "m",
            fontSize: "48px",
          },
        ],
      },
      {
        name: "headingxl",
        fontFamily: "primary",
        fontSize: "24px",
        lineHeight: "150%",
        fontWeight: "700",
        responsive: [
          {
            breakpoint: "m",
            fontSize: "35px",
          },
          {
            breakpoint: "xxl",
            fontSize: "48px",
          },
        ],
      },
      {
        name: "headingl",
        fontFamily: "primary",
        fontSize: "18px",
        lineHeight: "24px",
        fontWeight: "700",
        responsive: [
          {
            breakpoint: "m",
            fontSize: "30px",
            lineHeight: "36px",
          },
          {
            breakpoint: "xxl",
            fontSize: "36px",
            lineHeight: "47px",
          },
        ],
      },
      {
        name: "headingm",
        fontFamily: "primary",
        fontSize: "15px",
        lineHeight: "24px",
        fontWeight: "700",
        responsive: [
          {
            breakpoint: "m",
            fontSize: "28px",
            lineHeight: "30px",
          },
          {
            breakpoint: "xxl",
            fontSize: "30px",
            lineHeight: "40px",
          },
        ],
      },
      {
        name: "headings",
        fontFamily: "primary",
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: "700",
        responsive: [
          {
            breakpoint: "m",
            fontSize: "18px",
            lineHeight: "24px",
          },
          {
            breakpoint: "xxl",
            fontSize: "24px",
            lineHeight: "32px",
          },
        ],
      },
      {
        name: "headingxs",
        fontFamily: "primary",
        fontSize: "13px",
        lineHeight: "14px",
        fontWeight: "700",
        responsive: [
          {
            breakpoint: "m",
            fontSize: "15px",
            lineHeight: "16px",
          },
          {
            breakpoint: "xxl",
            fontSize: "18px",
            lineHeight: "19px",
          },
        ],
      },
      {
        name: "bodyLead",
        fontFamily: "primary",
        fontSize: "15px",
        lineHeight: "120%",
        fontWeight: "400",
        responsive: [
          {
            breakpoint: "m",
            fontSize: "18px",
            lineHeight: "24px",
            fontWeight: "400",
          },
          {
            breakpoint: "xxl",
            fontSize: "20px",
            lineHeight: "28px",
          },
        ],
      },
      {
        name: "body",
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "400",
        fontFamily: "primary",
        responsive: [
          {
            breakpoint: "m",
            fontSize: "15px",
            lineHeight: "19px",
          },
          {
            breakpoint: "xxl",
            fontSize: "17px",
            lineHeight: "22px",
          },
        ],
      },
      {
        name: "bodyInline",
        fontSize: "13px",
        lineHeight: "17px",
        fontFamily: "primary",
        fontWeight: "400",
        responsive: [
          {
            breakpoint: "m",
            fontSize: "13px",
            lineHeight: "18px",
          },
          {
            breakpoint: "xxl",
            fontSize: "15px",
            lineHeight: "21px",
          },
        ],
      },
      {
        name: "subtitle01",
        fontFamily: "primary",
        fontSize: "18px",
        lineHeight: "24px",
        fontWeight: "600",
        textAlign: "center",
        fontStyle: "normal",
        responsive: [
          {
            breakpoint: "m",
            fontSize: "18px",
            lineHeight: "24px",
          },
          {
            breakpoint: "xxl",
            fontSize: "22px",
            lineHeight: "36px",
          },
        ],
      },
      {
        name: "subtitle02",
        fontFamily: "primary",
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: "400",
        fontStyle: "normal",
        responsive: [
          {
            breakpoint: "m",
            fontSize: "15px",
            lineHeight: "22px",
          },
          {
            breakpoint: "xxl",
            fontSize: "18px",
            lineHeight: "24px",
          },
        ],
      },
      {
        name: "subtitle03",
        fontSize: "12px",
        lineHeight: "13px",
        fontWeight: "400",
        fontStyle: "normal",
        fontFamily: "primary",
        responsive: [
          {
            breakpoint: "m",
            fontSize: "13px",
            lineHeight: "14px",
          },
          {
            breakpoint: "xxl",
            fontSize: "14px",
            lineHeight: "15px",
          },
        ],
      },
      {
        name: "button",
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: "700",
        fontFamily: "primary",
        croptop: 3,
        cropbottom: 3,
        responsive: [
          {
            breakpoint: "m",
            fontSize: "16px",
            lineHeight: "20px",
            croptop: 3,
            cropbottom: 3,
          },
        ],
      },
      {
        name: "buttonM",
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: "700",
        fontFamily: "primary",
        croptop: 4,
        cropbottom: 4,
        responsive: [
          {
            breakpoint: "m",
            fontSize: "14px",
            lineHeight: "22px",
            croptop: 3,
            cropbottom: 3,
          },
        ],
      },
      {
        name: "buttonS",
        fontSize: "11px",
        lineHeight: "18px",
        fontWeight: "700",
        fontFamily: "primary",
        croptop: 3,
        cropbottom: 3,
        responsive: [
          {
            breakpoint: "m",
            fontSize: "11px",
            croptop: 3,
            cropbottom: 3,
          },
        ],
      },
      {
        name: "tiny",
        fontSize: "11px",
        lineHeight: "14px",
        fontWeight: "400",
        fontFamily: "primary",
      },
    ],
  },

  gradient: {
    description: "",
    gradients: {
      spectrum: {
        direction: "60deg",
        stops: [
          {
            color: "#ff0000",
            opacity: 1.0,
            position: "0%",
          },
          {
            color: "#ff00ff",
            opacity: 1.0,
            position: "15%",
          },
          {
            color: "#0000ff",
            opacity: 1.0,
            position: "33%",
          },
          {
            color: "#00ffff",
            opacity: 1.0,
            position: "49%",
          },
          {
            color: "#00ff00",
            opacity: 1.0,
            position: "67%",
          },
          {
            color: "#ffff00",
            opacity: 1.0,
            position: "84%",
          },
          {
            color: "#0000ff",
            opacity: 1.0,
            position: "100%",
          },
        ],
      },

      gradient01: {
        direction: "to top",
        stops: [
          {
            color: "#443CF3",
            opacity: 1.0,
            position: "0%",
          },
          {
            color: "#425FF7",
            opacity: 1.0,
            position: "100%",
          },
        ],
      },
    },
  },

  shadow: {
    description: "",
    shadows: {
      tiny: {
        x: 0,
        y: 3,
        blur: 14,
        color: "#000000",
        opacity: 0.13,
      },
      small: {
        x: 0,
        y: 10,
        blur: 19,
        color: "#000000",
        opacity: 0.15,
      },
      medium: {
        x: 0,
        y: 0,
        blur: 10,
        color: "#000000",
        opacity: 0.45,
      },
      big: {
        x: 0,
        y: 0,
        blur: 20,
        color: "#000000",
        opacity: 0.5,
      },
    },
  },

  timing: {
    xs: "0.12s",
    s: "0.25s",
    m: "0.5s",
    l: "1s",
    hover: "0.25s",
    stickyNavigation: "0.8s",
    moduleFadeIn: "0.66s",
    moduleTranslate: "24px",
  },

  radii: {
    s: "4px",
    m: "10px",
    l: "20px",
    button: "4px",
    circle: "999px",
  },

  line: {
    s: "1px",
    m: "2px",
    l: "4px",
    button: "1px",
  },

  spacing: {
    xxxs: "4px",
    xxs: "8px",
    xs: "16px",
    s: "24px",
    m: "32px",
    l: "48px",
    xl: "56px",
    xxl: "64px",
    xxxl: "80px",
    buttonM: {
      minWidth: "0px",
      minHeight: "0px",
      paddingX: "20px",
      paddingY: "20px",
      iconSize: "18px",
      ladding: "32px",
    },
    buttonS: {
      minWidth: "184px",
      minHeight: "32px",
      paddingX: "10px",
      paddingY: "10px",
      ladding: "32px",
      iconSize: "14px",
    },
    buttonFeatured: {
      minWidth: "350px",
      minHeight: "56px",
      paddingX: "24px",
      paddingY: "24px",
      ladding: "32px",
      iconSize: "24px",
    },
  },
};
