import React, { useEffect, useRef, useState } from "react";
import { useApp, useAuth } from "@contexts";
import { Wrapper } from "./style";
import {
  getUserClaps,
  getWrappedDates,
  getWrappedSteps,
  getUserSteps,
} from "./utils";
import { WRAPPED_STEPS } from "./constants";
import Steps from "./Steps";
import Welcome from "./Welcome";
import Days from "./Days";
import Claps, { ClapsProps } from "./Claps";
import Taxonomy from "./Taxonomy";
import Camels, { CamelsProps } from "./Camels";
import Google from "./Google";
import HubPlanner from "./HubPlanner";
import GoogleEmails from "./GoogleEmails";
import Farewell from "./Farewell";

const Wrapped = () => {
  const { people } = useApp(); // images
  const auth = useAuth();
  const { user } = auth;

  const [active, setActive] = useState(0);
  const stepsDuration = useRef(getWrappedSteps());
  const [clapsProps, setClapsProps] = useState<ClapsProps>();
  const [camelsProps, setCamelsProps] = useState<CamelsProps>();

  const { start, end } = getWrappedDates();

  const nextScreen = (screenToActive: number) => {
    if (
      !stepsDuration ||
      screenToActive >= stepsDuration.current.length ||
      screenToActive <= active
    ) {
      return;
    }

    setActive(screenToActive);

    setTimeout(() => {
      nextScreen(screenToActive + 1);
    }, stepsDuration.current[screenToActive]);
  };

  const getClaps = async () => {
    const props = await getUserClaps(start, end, people);
    setClapsProps(props);
  };

  const getCamels = async () => {
    if (user) {
      const props = await getUserSteps(start, end, user);
      setCamelsProps(props);
    }
  };

  useEffect(() => {
    if (!people.length) {
      return;
    }

    getClaps();
    getCamels();

    setTimeout(() => {
      nextScreen(active + 1);
    }, stepsDuration.current[active]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [people]);

  useEffect(() => {
    if (clapsProps && camelsProps) {
      const duration = getWrappedSteps(clapsProps, camelsProps);
      stepsDuration.current = duration;
    }
  }, [clapsProps, camelsProps]);

  return (
    <Wrapper>
      <Steps
        active={active}
        total={WRAPPED_STEPS.length}
        duration={stepsDuration.current || []}
      />

      <div>
        <Welcome active={active === 0} year={end.getFullYear()} />
        <Days active={active === 1} wrappedDate={end} />
        <GoogleEmails
          active={active === 2}
          wrappedEnd={end}
          wrappedStart={start}
        />
        <HubPlanner
          active={active === 3}
          wrappedStart={start}
          wrappedEnd={end}
        />
        <Claps active={active === 4} {...clapsProps} />
        <Google active={active === 5} wrappedStart={start} wrappedEnd={end} />
        <Camels active={active === 6} {...camelsProps} />
        <Taxonomy active={active === 7} wrappedStart={start} wrappedEnd={end} />
        <Farewell active={active === 8} year={end.getFullYear()} />
      </div>
    </Wrapper>
  );
};

export default Wrapped;
