import React from "react";
import { Stack } from "@sqymagma/elements";
import { useHistory } from "react-router";

import { IconNames } from "@elements/Icon";
import { Icon, Tooltip } from "@elements";

import * as S from "./style";

const Button = (props: Props) => {
  const {
    href,
    to,
    action,
    rightIcon,
    leftIcon,
    children,
    animateHover = false,
    tooltip,
    ...rest
  } = props;
  const { disabled = false, color, appearance } = rest;

  const history = useHistory();

  const handleClick = () => {
    if (disabled) return;
    action && action();
    to && history.push(to);
  };

  const isSecondary = appearance === "secondary";
  const isTertiary = appearance === "tertiary";

  return (
    <Tooltip content={tooltip} hideOnClick>
      <S.StyledButton
        href={href}
        to={to}
        onClick={handleClick}
        as={href ? "a" : "button"}
        $animateHover={animateHover}
        {...rest}
      >
        <Stack alignItems="flex-end" hs={isTertiary ? "xxxs" : "xxs"}>
          {leftIcon && (
            <S.IconPlace
              disabled={disabled}
              color={color}
              className="left-icon"
            >
              <Icon
                name={leftIcon}
                height={16}
                color={
                  disabled ? "disabled01" : isSecondary ? "text02" : "text01"
                }
              />
            </S.IconPlace>
          )}
          {children && (
            <S.Span disabled={disabled} color={color}>
              {children}
            </S.Span>
          )}
          {rightIcon && (
            <S.IconPlace
              disabled={disabled}
              color={color}
              className="right-icon"
            >
              <Icon
                name={rightIcon}
                height={16}
                color={
                  disabled ? "disabled01" : isSecondary ? "text02" : "text01"
                }
              />
            </S.IconPlace>
          )}
        </Stack>
      </S.StyledButton>
    </Tooltip>
  );
};

interface Props {
  children?: string;
  href?: string;
  to?: string;
  action?: () => void;
  rightIcon?: IconNames;
  leftIcon?: IconNames;
  animateHover?: boolean;
  disabled?: boolean;
  color?: string;
  appearance?: "primary" | "secondary" | "tertiary";
  target?: string;
  rounded?: boolean;
  shadow?: boolean;
  tooltip?: string;
}

export default Button;
