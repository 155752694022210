import React, { createContext } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { Loading } from "@components";
import { SWorker } from "@types";

import { AppState } from "./reducer";
import { useAppProvider } from "./hooks";

interface ProviderProps {
  serviceWorker: SWorker;
  children: JSX.Element;
}

interface ProviderValue extends AppState {
  serviceWorker: SWorker;
  isDataLoaded: boolean;
  getEmails: (number: number) => Promise<void>;
  markReadEmail: (id: string) => Promise<void>;
  addRecentTools: (newTool: string) => Promise<void>;
  updateMeetings: (meetings: string[]) => Promise<void>;
  changeSelectedDay: (selectedDay: Date) => void;
  changeSelectedMonth: (date: Date) => void;
  setCurrentDay: () => void;
  addShortUrl: (newUrl: string) => Promise<void>;
  removeShortUrl: (
    deletedUrl: string,
    deletedShortUrl: string
  ) => Promise<void>;
  fireReward: () => void;
  deleteEvent: (calendarName: string, eventId: string) => Promise<void>;
  changeConfig: (option: Record<string, unknown>) => Promise<void>;
  getRemainingClaps: () => Promise<void>;
}

export const AppContext = createContext({} as ProviderValue);

export const AppProvider = (props: ProviderProps) => {
  const { children, serviceWorker } = props;
  const app = useAppProvider();

  return (
    <AppContext.Provider value={{ ...app, serviceWorker }}>
      <AnimatePresence>
        {!app.isDataLoaded ? (
          <Loading />
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </AppContext.Provider>
  );
};
