import React, { useRef, useState } from "react";
import { HStack, VStack, Text, Box } from "@sqymagma/elements";
import { Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { useApp, useAuth } from "@contexts";
import { SideModal, WeatherWidget, Config } from "@components";
import { IconLink, Avatar } from "@elements";
import { useHandleClickOutside, useModal } from "@hooks";

import Changelog from "./Changelog";
import CommentsNotifier from "./CommentsNotifier";
import * as S from "./style";

const Header = () => {
  const {
    serviceWorker: { showReload, reloadPage },
  } = useApp();
  const auth = useAuth();
  const { user } = auth;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { isOpen, toggleModal } = useModal();

  const location = useLocation();
  const isHome = location.pathname === "/";

  const toggleMenu = () => setIsMenuOpen((isOpen) => !isOpen);

  const handleClickOutside = (e: MouseEvent) => {
    if (menuRef.current && menuRef.current.contains(e.target as Node)) return;
    toggleMenu();
  };
  useHandleClickOutside(isMenuOpen, handleClickOutside);

  const handleSignOut = () => {
    auth.signOut();
  };

  const User = () =>
    user && (
      <HStack gap={{ default: "xxs", m: "xs" }} alignItems="center">
        <Avatar image={user.image} name={user.name} size="l" />
        <VStack display={{ default: "none", m: "block" }}>
          <Text textStyle="subtitle01" caps>
            {user.name}
          </Text>
          <Text textStyle="bodyInline">{user.email}</Text>
        </VStack>
      </HStack>
    );

  return (
    <>
      <S.Header
        $isHome={isHome}
        mb={{ default: "xxs", s: "s" }}
        display={{ default: "none", s: "flex" }}
      >
        <Route exact path="/">
          <S.WeatherWrapper>
            <WeatherWidget />
          </S.WeatherWrapper>
        </Route>
        <HStack gap="xs" alignItems="center">
          <HStack gap="s" mr="xxs" alignItems="center">
            <CommentsNotifier />
            <Changelog />
          </HStack>
          <User />
          <Box position="relative" ref={menuRef}>
            <IconLink
              action={toggleMenu}
              icon="arrowDown"
              rotated={isMenuOpen}
              small
              inverted
            />
            {showReload && <S.Badge />}
            <AnimatePresence>
              {isMenuOpen && (
                <S.Menu
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ ease: "easeInOut", duration: 0.15 }}
                  onClick={toggleMenu}
                >
                  {showReload && (
                    <S.MenuItem onClick={reloadPage}>
                      Actualizar y reiniciar
                    </S.MenuItem>
                  )}
                  <S.MenuItem onClick={toggleModal}>Configuración</S.MenuItem>
                  <S.MenuItem onClick={handleSignOut}>Salir</S.MenuItem>
                </S.Menu>
              )}
            </AnimatePresence>
          </Box>
        </HStack>
      </S.Header>
      <SideModal title="Configuración" isOpen={isOpen} hide={toggleModal}>
        <Config />
      </SideModal>
    </>
  );
};

export default Header;
