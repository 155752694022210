import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence } from "framer-motion";

import * as S from "./style";

const Tooltip = (props: Props) => {
  const { content, children, hideOnClick, bottom, onClick } = props;

  const initialState = {
    active: false,
    childrenWidth: 0,
    fixOutOfBounds: 0,
  };
  const [state, setState] = useState(initialState);
  const { active, childrenWidth, fixOutOfBounds } = state;

  const changeState = (value: Partial<typeof initialState>) => {
    setState((state) => ({ ...state, ...value }));
  };

  const childrenRef = useRef<HTMLDivElement>(null);
  const tipRef = useRef<HTMLDivElement>(null);

  let timeout: ReturnType<typeof setTimeout>;

  const showTip = () => {
    timeout = setTimeout(() => {
      changeState({ active: true });
    }, 500);
  };

  const hideTip = () => {
    clearInterval(timeout);
    changeState({ active: false });
  };

  const handleClick = () => {
    if (hideOnClick) {
      hideTip();
    }
  };

  useEffect(() => {
    if (childrenRef.current) {
      const childrenWidth = childrenRef.current.children[0].clientWidth;
      changeState({ childrenWidth });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenRef.current?.children[0].clientWidth]);

  useEffect(() => {
    if (active && tipRef.current) {
      const clientRect = tipRef.current.getBoundingClientRect();
      const { left, right } = clientRect;
      const windowSize = window.innerWidth;
      const safetyMargin = 10; // in pixels
      if (left < 0) {
        changeState({ fixOutOfBounds: left + safetyMargin });
      } else if (right > windowSize) {
        changeState({ fixOutOfBounds: right - windowSize + safetyMargin });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipRef, active]);

  if (!content) return children;

  return (
    <S.Tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      onMouseDown={handleClick}
      onClick={onClick}
    >
      <div ref={childrenRef}>{children}</div>
      <AnimatePresence>
        {active && (
          <S.Tip
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.25 }}
            $childrenWidth={childrenWidth}
            bottom={bottom}
            ref={tipRef}
            $fixOutOfBounds={fixOutOfBounds}
          >
            {content}
          </S.Tip>
        )}
      </AnimatePresence>
    </S.Tooltip>
  );
};

interface Props {
  content?: string;
  children: JSX.Element;
  hideOnClick?: boolean;
  bottom?: boolean;
  onClick?: () => void;
}

export default Tooltip;
