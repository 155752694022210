import styled, { css } from "styled-components";
import { Box } from "@sqymagma/elements";
import { theme } from "@sqymagma/theme";

const WidgetBox = styled(Box)<{ hideOverflow?: boolean; $noShadow?: boolean }>`
  position: relative;
  border-radius: ${(p) => p.theme.radii.l};
  padding: ${(p) => p.theme.spacing.s};
  box-shadow: none;

  ${(p) =>
    !p.bg &&
    css`
      background-color: ${(p) => p.theme.colors.quinaryBackground};
    `}

  ${(p) =>
    p.hideOverflow &&
    css`
      overflow: hidden;
    `}

  @media (min-width: ${theme("breakpoints.s")}) {
    box-shadow: ${(p) => (p.$noShadow ? "none" : p.theme.shadows.small)};

    ${(p) =>
      !p.bg &&
      css`
        background-color: ${(p) => p.theme.colors.tertiaryBackground};
      `}
  }
`;

export default WidgetBox;
