import React from "react";
import { VStack, Text, Columns, Box, Column } from "@sqymagma/elements";

import { useAuth, useApp } from "@contexts";
import { getGreeting, getToday } from "./utils";
import {
  CalendarWidget,
  Emails,
  RecentTools,
  Taxonomy,
  MeetingsWidget,
  WeLog,
  Japi,
  CamelSteps,
  CamelsWidget,
  VirtualCoffee,
  UrlShortener,
  ApplauseWidget,
  WidgetBox,
  MoodWidget,
} from "@components";
import { IconLink } from "@elements";

import * as S from "./style";

const Home = () => {
  const { user } = useAuth();
  const { recentTools, rewardType, fireReward } = useApp();

  const today = getToday();

  return (
    <Columns
      hs="m"
      vs={{ default: "xs", s: "m" }}
      overflow={{ default: "hidden", s: "visible" }}
    >
      <Column width={[1, 1, "50%", "50%", "30%"]} pb={{ default: 0, xl: "m" }}>
        <VStack gap={{ default: 0, s: "s" }}>
          <Box display={{ default: "none", s: "block" }}>
            <VStack gap="xxs">
              <Text textStyle="subtitle01" color="text01">
                {today}
              </Text>
              <S.Greeting
                $rewardType={rewardType}
                onClick={rewardType && fireReward}
              >
                {getGreeting(rewardType, user)}
              </S.Greeting>
            </VStack>
          </Box>

          <VStack gap={{ default: "xs", s: "m" }}>
            <WidgetBox>
              <MeetingsWidget />
            </WidgetBox>

            {recentTools.length && (
              <WidgetBox>
                <RecentTools />
              </WidgetBox>
            )}
          </VStack>
        </VStack>
      </Column>

      <Column width={[1, 1, "50%", "50%", "35%"]} pb={{ default: 0, xl: "m" }}>
        <VStack gap={{ default: "xs", s: "m" }}>
          <WidgetBox height="270px" overflowY="scroll">
            <Emails />
          </WidgetBox>

          <ApplauseWidget
            actionElements={
              <IconLink
                icon="barChart"
                to="/applauses"
                tooltip="Timeline y estadísticas"
              />
            }
          />

          <WidgetBox>
            <VStack gap="xxs">
              <Text textStyle="subtitle01" color="text01" weight="bold">
                Acortador de Url
              </Text>
              <UrlShortener />
            </VStack>
          </WidgetBox>

          <Columns
            cols={{ xs: 2, s: 2, m: 1, l: 2 }}
            hs="xs"
            vs="xs"
            overflow="visible"
          >
            <S.MiniWidgetBox bg="#A950CF">
              <Japi />
            </S.MiniWidgetBox>
            <S.MiniWidgetBox bg="#3CC3DD">
              <Taxonomy />
            </S.MiniWidgetBox>
            <S.MiniWidgetBox bg="#2FCC83">
              <WeLog />
            </S.MiniWidgetBox>
            <S.MiniWidgetBox bg="#FFB84C">
              <CamelsWidget />
            </S.MiniWidgetBox>
          </Columns>
        </VStack>
      </Column>

      <Column
        width={[1, 1, "50%", "50%", "35%"]}
        pb={{ default: "xs", s: "m" }}
      >
        <VStack gap={{ default: "xs", s: "m" }}>
          <MoodWidget />
        </VStack>

        <VirtualCoffee />

        <VStack mt={{ default: "xs", s: "m" }} gap={{ default: "xs", s: "m" }}>
          {new Date().getDay() === 1 && (
            <WidgetBox>
              <CamelSteps />
            </WidgetBox>
          )}

          <WidgetBox>
            <CalendarWidget />
          </WidgetBox>
        </VStack>
      </Column>
    </Columns>
  );
};

export default Home;
